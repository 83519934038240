<template>
  <div :class="[bgColor, marginStyles]" class="rounded-md px-4">
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <component
          :is="icon"
          :class="iconColor"
          class="h-5 w-5"
          aria-hidden="true"
        />
      </div>
      <div :class="textColor" class="ml-3 flex-1 md:flex text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  SparklesIcon,
  XCircleIcon,
} from "@heroicons/vue/20/solid";
import { computed } from "vue";

const props = defineProps(["type", "marginStyles"]);

const icon = computed(() => {
  switch (props.type) {
    case "caution":
      return ExclamationTriangleIcon;
    case "check":
      return CheckCircleIcon;
    case "tip":
      return SparklesIcon;
    case "warning":
      return XCircleIcon;
    default:
      return InformationCircleIcon;
  }
});

const bgColor = computed(() => {
  switch (props.type) {
    case "caution":
      return "border border-yellow-700 bg-yellow-50";
    case "check":
      return "border border-green-700 bg-green-50";
    case "tip":
      return "border border-teal-700 bg-teal-50";
    case "warning":
      return "border border-red-700 bg-red-50";
    default:
      return "border border-blue-700 bg-blue-50";
  }
});

const iconColor = computed(() => {
  switch (props.type) {
    case "caution":
      return "text-yellow-400";
    case "check":
      return "text-green-400";
    case "tip":
      return "text-teal-400";
    case "warning":
      return "text-red-400";
    default:
      return "text-blue-400";
  }
});

const textColor = computed(() => {
  switch (props.type) {
    case "caution":
      return "text-yellow-700";
    case "check":
      return "text-green-700";
    case "tip":
      return "text-teal-700";
    case "warning":
      return "text-red-700";
    default:
      return "text-blue-700";
  }
});
</script>
