import InfoCallout from "@/components/help-center/custom-tags/InfoCallout.vue";

export const callout = {
  render: InfoCallout,
  attributes: {
    type: {
      type: String,
      default: "note",
      required: true,
      matches: ["caution", "check", "note", "warning", "tip"],
      errorLevel: "warning",
    },
    marginStyles: {
      type: String,
    },
  },
};
